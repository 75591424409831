import { Injectable } from '@angular/core';
import { DynamicAssetLoaderService, DynamicLoaderInlineAssets } from '@rma/generic/util/dynamic-asset-loader';
import { Environment } from '@rma/generic/util/environment';

@Injectable({
  providedIn: 'root',
})
export class HotjarScriptService {
  constructor(
    private readonly environment: Environment,
    private readonly dynamicAssetLoader: DynamicAssetLoaderService,
  ) {}

  public appendScript(): void {
    if (this.environment.tracking.hotjarId) {
      const i = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${this.environment.tracking.hotjarId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=true;r.defer=true;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');
      `;

      this.dynamicAssetLoader.appendInlineAsset(DynamicLoaderInlineAssets.Hotjar, i);
    }
  }
}
